<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-row :gutter="10" class="top_l">
                    <el-col :span="24" :xs="24" class="elel">
                        <el-button type="primary" @click="dialogAdd = true">新增</el-button>
                        <div class="readbox">
                            <el-tag size="large">查询是否可用：</el-tag>
                            <el-radio-group v-model="status" @change="radioes">
                                <el-radio label="" border>全部</el-radio>
                                <el-radio :label="1" border>是</el-radio>
                                <el-radio :label="0" border>否</el-radio>
                            </el-radio-group>
                        </div>
                    </el-col>
                </el-row>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
                    stripe>
                    <el-table-column prop="id" label="ID" align="center" width="80"></el-table-column>
                    <el-table-column prop="productId" label="产品ID"></el-table-column>
                    <el-table-column label="类型">
                        <template v-slot="scope">
                            <span v-if="scope.row.type == 0">会员</span>
                            <span v-else-if="scope.row.type == 1">vip直冲</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支持签约否">
                        <template v-slot="scope">
                            <span v-if="scope.row.supportSign == 0">不支持</span>
                            <span v-else-if="scope.row.supportSign == 1">支持</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="签约场景">
                        <template v-slot="scope">
                            <span v-if="scope.row.supportSign == 1">{{ scope.row.signScene }}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="扣款周期-天数">
                        <template v-slot="scope">
                            <span v-if="scope.row.supportSign == 1">{{ scope.row.cyclePayDay }}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="会员天数">
                        <template v-slot="scope">
                            <span v-if="scope.row.type == 0">{{ scope.row.vipDay }}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付金额类型">
                        <template v-slot="scope">
                            <span v-if="scope.row.moneyType == 0">固定金额</span>
                            <span v-else-if="scope.row.moneyType == 1">随机金额</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" label="固定支付金额"></el-table-column>
                    <el-table-column prop="minMoney" label="最小支付金额"></el-table-column>
                    <el-table-column prop="maxMoney" label="最大支付金额"></el-table-column>
                    <el-table-column label="附加信息">
                        <template v-slot="scope">
                            <span>
                                {{ JSON.stringify(scope.row.ext) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用">
                        <template v-slot="scope">
                            <el-switch @change="switchClick(scope.row)" v-model="scope.row.status"
                                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
                                :inactive-value="0" active-text="是" inactive-text="否" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                    <el-table-column label="操作" align="center" width="100">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增-配置 -->
                <el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="类型" prop="type">
                                    <el-select v-model="ruleForm.type" clearable placeholder="请选择类型">
                                        <el-option :key="0" label="会员" :value="0" />
                                        <el-option :key="1" label="vip直冲" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.type === 0">
                                <el-form-item label="会员天数" prop="vipDay">
                                    <el-input-number v-model="ruleForm.vipDay" :min="0" placeholder="请输入会员天数" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <el-form-item label="支持签约否" prop="supportSign">
                                    <el-select v-model="ruleForm.supportSign" clearable placeholder="请选择支持签约否">
                                        <el-option :key="0" label="不支持" :value="0" />
                                        <el-option :key="1" label="支持" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <el-form-item label="支付金额类型" prop="moneyType">
                                    <el-select v-model="ruleForm.moneyType" clearable placeholder="请选择支付金额类型">
                                        <el-option :key="0" label="固定金额" :value="0" />
                                        <el-option :key="1" label="随机金额" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.supportSign === 1">
                                <el-form-item label="商家扣款周期-天数" prop="cyclePayDay">
                                    <el-input-number v-model="ruleForm.cyclePayDay" :min="0"
                                        placeholder="请输入商家扣款周期-天数" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.supportSign === 1">
                                <el-form-item label="签约场景" prop="signScene">
                                    <el-input v-model="ruleForm.signScene" clearable placeholder="请输入签约场景"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.moneyType === 0">
                                <el-form-item label="固定支付金额" prop="money">
                                    <el-input-number v-model="ruleForm.money" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入固定支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.moneyType === 1">
                                <el-form-item label="最小支付金额" prop="minMoney">
                                    <el-input-number v-model="ruleForm.minMoney" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入最小支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="ruleForm.moneyType === 1">
                                <el-form-item label="最大支付金额" prop="maxMoney">
                                    <el-input-number v-model="ruleForm.maxMoney" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入最大支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="附加信息" prop="ext">
                                    <el-input v-model="ruleForm.ext" type="textarea" clearable :rows="5"
                                        placeholder="请输入附加信息"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- 修改-配置 -->
                <el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="150px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="类型" prop="type">
                                    <el-select v-model="EditruleForm.type" clearable placeholder="请选择支持签约否">
                                        <el-option :key="0" label="会员" :value="0" />
                                        <el-option :key="1" label="vip直冲" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.type === 0">
                                <el-form-item label="会员天数" prop="vipDay">
                                    <el-input-number v-model="EditruleForm.vipDay" :min="1" placeholder="请输入会员天数" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <el-form-item label="支持签约否" prop="supportSign">
                                    <el-select v-model="EditruleForm.supportSign" clearable placeholder="请选择支持签约否">
                                        <el-option :key="0" label="不支持" :value="0" />
                                        <el-option :key="1" label="支持" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <el-form-item label="支付金额类型" prop="moneyType">
                                    <el-select v-model="EditruleForm.moneyType" clearable placeholder="请选择支付金额类型">
                                        <el-option :key="0" label="固定金额" :value="0" />
                                        <el-option :key="1" label="随机金额" :value="1" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.supportSign === 1">
                                <el-form-item label="扣款周期-天数" prop="cyclePayDay">
                                    <el-input-number v-model="EditruleForm.cyclePayDay" :min="1"
                                        placeholder="请输入扣款周期-天数" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.supportSign === 1">
                                <el-form-item label="签约场景" prop="signScene">
                                    <el-input v-model="EditruleForm.signScene" clearable
                                        placeholder="请输入签约场景"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.moneyType === 0">
                                <el-form-item label="固定支付金额" prop="money">
                                    <el-input-number v-model="EditruleForm.money" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入固定支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.moneyType === 1">
                                <el-form-item label="最小支付金额" prop="minMoney">
                                    <el-input-number v-model="EditruleForm.minMoney" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入最小支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24" v-if="EditruleForm.moneyType === 1">
                                <el-form-item label="最大支付金额" prop="maxMoney">
                                    <el-input-number v-model="EditruleForm.maxMoney" :precision="2" :step="0.01"
                                        :min="0.01" placeholder="请输入最大支付金额" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="附加信息" prop="ext">
                                    <el-input v-model="EditruleForm.ext" type="textarea" clearable :rows="5"
                                        placeholder="请输入附加信息"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
                            <el-button @click="dialogEdit = false">取消</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'paymentConfit',
    data() {
        return {
            is: false,
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            status: '',
            // 新增配置
            dialogAdd: false,
            ruleForm: {
                supportSign: 0,
                vipDay: null,
                cyclePayDay: null,
                signScene: '',
                money: null,
                type: 0,
                minMoney: null,
                maxMoney: null,
                moneyType: 0,
                ext: '',
            },
            rules: {
                supportSign: [{
                    required: true,
                    message: '请输入支持签约否',
                    trigger: 'change'
                }],
                type: [{
                    required: true,
                    message: '请输入类型',
                    trigger: 'change'
                }],
                moneyType: [{
                    required: true,
                    message: '请选择金额类型',
                    trigger: 'change'
                }],
                cyclePayDay: [{
                    required: true,
                    message: '请输入商家扣款周期-天数',
                    trigger: 'blur'
                }],
                vipDay: [{
                    required: true,
                    message: '请输入会员天数',
                    trigger: 'blur'
                }],
                signScene: [{
                    required: true,
                    message: '请输入签约场景',
                    trigger: 'blur'
                }],
                money: [{
                    required: true,
                    message: '请输入固定支付金额',
                    trigger: 'blur'
                }],
                minMoney: [{
                    required: true,
                    message: '请输入最小支付金额',
                    trigger: 'blur'
                }],
                maxMoney: [{
                    required: true,
                    message: '请输入最大支付金额',
                    trigger: 'blur'
                }],
                ext: [{
                    required: true,
                    message: '请输入附加信息',
                    trigger: 'blur'
                }],

            },
            // 编辑
            dialogEdit: false,
            EditruleForm: {
                id: '',
                supportSign: '',
                type: '',
                vipDay: null,
                cyclePayDay: null,
                signScene: '',
                money: null,
                minMoney: null,
                maxMoney: null,
                moneyType: 0,
                ext: '',
            }
        };
    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }

        this.productId = this.$TOOL.data.get('DATA_SELECTEED')
        // 获取配置
        this.PaymentConfit();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval
                // 获取配置
                this.PaymentConfit();
            }
        }
    },
    methods: {
        // 获取配置
        PaymentConfit() {
            this.serloading = true;
            this.$HTTP.post(
                'tradePayConfig/query', {
                status: this.status,
                productId: this.productId,
                pageNumber: this.currentPage,
                pageSize: this.pageSize
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            console.log(response.data.data)
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        //查新是否可用
        radioes() {
            this.currentPage = 1;
            this.PaymentConfit();
        },
        // 是否可用
        switchClick(row) {
            this.$HTTP.post(
                'tradePayConfig/updateStatus', {
                id: row.id,
                status: row.status
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取配置
                            this.PaymentConfit();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 新增-配置
        submitClick(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'tradePayConfig/add', {
                        productId: this.productId,
                        supportSign: this.ruleForm.supportSign,
                        vipDay: this.ruleForm.vipDay,
                        type: this.ruleForm.type,
                        cyclePayDay: this.ruleForm.cyclePayDay,
                        signScene: this.ruleForm.signScene,
                        money: this.ruleForm.money,
                        moneyType: this.EditruleForm.moneyType,
                        minMoney: this.EditruleForm.minMoney,
                        maxMoney: this.EditruleForm.maxMoney,
                        ext: JSON.parse(this.ruleForm.ext)
                    },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.dialogAdd = false;
                                    this.$message.success(response.data.msg);
                                    this.resetClick(formName);
                                    // 获取配置
                                    this.PaymentConfit();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        // 编辑
        clcedit(row) {
            this.EditruleForm.supportSign = row.supportSign;
            this.EditruleForm.type = row.type;
            this.EditruleForm.cyclePayDay = row.cyclePayDay;
            this.EditruleForm.vipDay = row.vipDay;
            this.EditruleForm.signScene = row.signScene;
            this.EditruleForm.money = row.money;
            this.EditruleForm.moneyType = row.moneyType;
            this.EditruleForm.minMoney = row.minMoney;
            this.EditruleForm.maxMoney = row.maxMoney;
            this.EditruleForm.ext = JSON.stringify(row.ext);
            this.EditruleForm.id = row.id;
            this.dialogEdit = true;
        },
        EditsubmitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'tradePayConfig/update', {
                        id: this.EditruleForm.id,
                        productId: this.productId,
                        supportSign: this.EditruleForm.supportSign,
                        type: this.EditruleForm.type,
                        cyclePayDay: this.EditruleForm.cyclePayDay,
                        vipDay: this.EditruleForm.vipDay,
                        signScene: this.EditruleForm.signScene,
                        money: this.EditruleForm.money,
                        moneyType: this.EditruleForm.moneyType,
                        minMoney: this.EditruleForm.minMoney,
                        maxMoney: this.EditruleForm.maxMoney,
                        ext: JSON.parse(this.EditruleForm.ext)
                    },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.dialogEdit = false;
                                    this.$message.success(response.data.msg);
                                    // 获取配置
                                    this.PaymentConfit();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },

        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取配置
            this.PaymentConfit();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取配置
            this.PaymentConfit();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
    width: 100%;
}

.readbox {
    display: inline-block;
    margin-left: 20px;

    span {
        margin-right: 10px;
        font-size: 16px;
    }
}

.elel {
    display: flex;
    align-items: center;
}
</style>