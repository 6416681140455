<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-form ref="form" :model="form" label-width="90px">
                    <el-row :gutter="10">
                        <el-col :span="6" :xs="24">
                            <el-form-item label="商户号"><el-input v-model="form.merchantId" clearable
                                    placeholder="请输入商户号"></el-input></el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="所属主体"><el-input v-model="form.state" clearable
                                    placeholder="请输入所属主体"></el-input></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="top_l">
                        <el-col :span="24" :xs="24">
                            <el-button type="primary" @click="jouShare">搜索</el-button>
                            <el-button type="primary" style="margin-left: 20px;"
                                @click="dialogAdd = true">新增</el-button>
                            <el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
                    stripe>
                    <el-table-column prop="state" label="所属主体"></el-table-column>
                    <el-table-column prop="merchantId" label="商户号"></el-table-column>
                    <el-table-column label="自己私钥" width="300">
                        <template #default="scope">
                            <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                <template #default>
                                    <div style="width: 400px;"> {{ scope.row.selfPriKey }}</div>
                                </template>
                                <template #reference>
                                    <div class="tablecell">{{ scope.row.selfPriKey }}</div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column label="微信公钥" width="300">
                        <template #default="scope">
                            <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                <template #default>
                                    <div style="width: 400px;"> {{ scope.row.wechatPubKey }}</div>
                                </template>
                                <template #reference>
                                    <div class="tablecell">{{ scope.row.wechatPubKey }}</div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="wechatPubKeyId" label="微信公钥ID"></el-table-column>
                    <el-table-column prop="merchantSerialNumber" label="商户证书序列号"></el-table-column>
                    <el-table-column prop="wechatSerialNumber" label="微信证书序列号"></el-table-column>
                    <el-table-column prop="apiV3Key" label="API V3密钥"></el-table-column>
                    <el-table-column prop="limitCharge" label="收款限额（元）"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
                            <el-button size="small" type="primary" plain @click="clcedetele(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增-产品 -->
                <el-dialog v-model="dialogAdd" title="新增" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="180px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="商户号" prop="merchantId"><el-input placeholder="请输入商户号"
                                        v-model="ruleForm.merchantId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="所属主体" prop="state"><el-input placeholder="请输入所属主体"
                                        v-model="ruleForm.state"></el-input></el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="收款限额（元）" prop="limitCharge">
                                    <el-input-number style="width: 100%;" v-model="ruleForm.limitCharge" :precision="2"
                                        :step="0.1" :min="0" />
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="商户证书序列号" prop="merchantSerialNumber"><el-input
                                        placeholder="请输入商户证书序列号"
                                        v-model="ruleForm.merchantSerialNumber"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="API V3密钥" prop="apiV3Key"><el-input placeholder="请输入API V3密钥"
                                        v-model="ruleForm.apiV3Key"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信公钥ID"><el-input placeholder="请输入微信公钥ID"
                                        v-model="ruleForm.wechatPubKeyId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信证书序列号"><el-input placeholder="请输入微信证书序列号"
                                        v-model="ruleForm.wechatSerialNumber"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">

                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="自己私钥" prop="selfPriKey"><el-input :rows="6" type="textarea"
                                        placeholder="请输入自己私钥" v-model="ruleForm.selfPriKey"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信公钥"><el-input :rows="6" type="textarea" placeholder="请输入微信公钥"
                                        v-model="ruleForm.wechatPubKey"></el-input></el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- 修改 -->
                <el-dialog v-model="dialogEdit" title="修改" width="50%" :fullscreen="fullscreenshow">
                    <el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="180px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="商户号" prop="merchantId"><el-input placeholder="请输入商户号"
                                        v-model="EditruleForm.merchantId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="所属主体" prop="state"><el-input placeholder="请输入所属主体"
                                        v-model="EditruleForm.state"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="收款限额（元）" prop="limitCharge">
                                    <el-input-number style="width: 100%;" v-model="EditruleForm.limitCharge"
                                        :precision="2" :step="0.1" :min="0" />
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="商户证书序列号" prop="merchantSerialNumber"><el-input
                                        placeholder="请输入商户证书序列号"
                                        v-model="EditruleForm.merchantSerialNumber"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="API V3密钥" prop="apiV3Key"><el-input placeholder="请输入API V3密钥"
                                        v-model="EditruleForm.apiV3Key"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信公钥ID"><el-input placeholder="请输入微信公钥ID"
                                        v-model="EditruleForm.wechatPubKeyId"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信证书序列号"><el-input placeholder="请输入微信证书序列号"
                                        v-model="EditruleForm.wechatSerialNumber"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">

                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="自己私钥" prop="selfPriKey"><el-input :rows="6" type="textarea"
                                        placeholder="请输入自己私钥"
                                        v-model="EditruleForm.selfPriKey"></el-input></el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="微信公钥"><el-input :rows="6" type="textarea" placeholder="请输入微信公钥"
                                        v-model="EditruleForm.wechatPubKey"></el-input></el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
                            <el-button @click="dialogEdit = false">取消</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'wechatMerchantInfo',
    data() {
        return {
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',

            form: {
                merchantId: '',
                state: '',
            },


            dialogAdd: false,
            ruleForm: {
                merchantId: '',
                wechatPubKeyId: '',
                wechatSerialNumber: '',
                selfPriKey: '',
                wechatPubKey: '',
                merchantSerialNumber: '',
                apiV3Key: '',
                limitCharge: 0,
                state: '',
            },
            rules: {
                merchantId: [{ required: true, message: '请输入商户号', trigger: 'blur' }],
                selfPriKey: [{ required: true, message: '请输入自己私钥', trigger: 'blur' }],
                limitCharge: [{ required: true, message: '请输入收款限额（元）', trigger: 'blur' }],
                state: [{ required: true, message: '请输入所属主体', trigger: 'blur' }],
                apiV3Key: [{ required: true, message: '请输入API V3密钥', trigger: 'blur' }],
                merchantSerialNumber: [{ required: true, message: '请输入商户证书序列号', trigger: 'blur' }],
            },
            // 编辑
            dialogEdit: false,
            EditruleForm: {
                id: '',
                wechatPubKeyId: '',
                wechatSerialNumber: '',
                merchantId: '',
                selfPriKey: '',
                wechatPubKey: '',
                merchantSerialNumber: '',
                limitCharge: 0,
                state: '',
                apiV3Key: ''
            }
        };
    },
    created() {
        this.productId = this.$TOOL.data.get('DATA_SELECTEED');
        // 列表
        this.wechatMerchantInfolist();

        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }
    },
    methods: {
        // 列表
        wechatMerchantInfolist() {
            this.serloading = true;
            this.$HTTP.post(
                'wechatMerchantInfo/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    merchantId: this.form.merchantId,
                    state: this.form.state,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                    this.serloading = false;

                }
            );
        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        // 搜索
        jouShare() {
            this.serloading = true;
            this.currentPage = 1;
            // 列表
            this.wechatMerchantInfolist();
        },
        // 刷新缓存
        refreshCache() {
            this.serloading = true;
            this.$HTTP.post('wechatMerchantInfo/refreshCache', {}, response => {
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.code == 0) {
                        this.$message.success(response.data.msg);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } else {
                    console.log(response.message);
                }
                this.serloading = false;

            });
        },
        // 新增
        submitClick(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'wechatMerchantInfo/add',
                        {
                            productId: this.productId,
                            merchantId: this.ruleForm.merchantId,
                            wechatPubKeyId: this.ruleForm.wechatPubKeyId,
                            wechatSerialNumber: this.ruleForm.wechatSerialNumber,
                            selfPriKey: this.ruleForm.selfPriKey,
                            wechatPubKey: this.ruleForm.wechatPubKey,
                            merchantSerialNumber: this.ruleForm.merchantSerialNumber,
                            apiV3Key: this.ruleForm.apiV3Key,
                            limitCharge: this.ruleForm.limitCharge,
                            state: this.ruleForm.state,
                        },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.$message.success(response.data.msg);
                                    this.resetClick(formName);
                                    // 列表
                                    this.wechatMerchantInfolist();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                                this.dialogAdd = false;

                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },

        clcedetele(row) {
            this.serloading = true;
            this.$HTTP.post('wechatMerchantInfo/delete', {
                id: row.id,
                productId: this.productId,
            }, response => {
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.code == 0) {
                        this.$message.success(response.data.msg);
                        // 列表
                        this.wechatMerchantInfolist();
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } else {
                    console.log(response.message);
                }
                this.serloading = false;

            });
        },
        // 编辑
        clcedit(row) {
            this.EditruleForm.id = row.id;
            this.EditruleForm.merchantId = row.merchantId;
            this.EditruleForm.wechatPubKeyId = row.wechatPubKeyId;
            this.EditruleForm.wechatSerialNumber = row.wechatSerialNumber;
            this.EditruleForm.selfPriKey = row.selfPriKey;
            this.EditruleForm.wechatPubKey = row.wechatPubKey;
            this.EditruleForm.merchantSerialNumber = row.merchantSerialNumber;
            this.EditruleForm.apiV3Key = row.apiV3Key;
            this.EditruleForm.limitCharge = row.limitCharge;
            this.EditruleForm.state = row.state;
            this.dialogEdit = true;
        },
        EditsubmitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$HTTP.post(
                        'wechatMerchantInfo/update',
                        {
                            id: this.EditruleForm.id,
                            productId: this.productId,
                            wechatPubKeyId: this.EditruleForm.wechatPubKeyId,
                            wechatSerialNumber: this.EditruleForm.wechatSerialNumber,
                            merchantId: this.EditruleForm.merchantId,
                            selfPriKey: this.EditruleForm.selfPriKey,
                            wechatPubKey: this.EditruleForm.wechatPubKey,
                            merchantSerialNumber: this.EditruleForm.merchantSerialNumber,
                            apiV3Key: this.EditruleForm.apiV3Key,
                            limitCharge: this.EditruleForm.limitCharge,
                            state: this.EditruleForm.state,
                        },
                        response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.$message.success(response.data.msg);
                                    // 列表
                                    this.wechatMerchantInfolist();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                                this.dialogEdit = false;
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 列表
            this.wechatMerchantInfolist();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 列表
            this.wechatMerchantInfolist();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tablecell {
    white-space: nowrap; //强制不换行
    overflow: hidden; //溢出隐藏
    text-overflow: ellipsis //替换为省略号
}
</style>
